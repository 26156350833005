<template>
  <div class="order-card position-relative w-100 h-100 shadow rounded-bottom">
    <div class="position-absolute w-100 h-100 rounded overflow-hidden">
      <strapi-image-component
        v-if="backgroundImage && backgroundImage.url"
        :image="backgroundImage"
        image-class="w-100"
        alt-fallback="Order Configuration Background"
        :lazy="false"
        fluid
      />
      <div
        v-if="showDiscount"
        class="order-card__discount position-absolute end-0 px-2 py-1"
      >
        25% OFF
      </div>
    </div>

    <div
      v-if="hasHighlightSlot"
      class="order-card__highlight fs-tiny rounded-top"
    >
      <slot name="highlight"></slot>
    </div>

    <div
      class="order-card__inner position-relative d-flex flex-column align-content-center h-100 rounded p-4"
      :class="{
        'order-card__inner--selected': selected,
      }"
    >
      <slot name="default"></slot>

      <div v-if="hasButtonSlot" class="mt-auto d-grid">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { StrapiImage } from '~/apollo/types/types';
import StrapiImageComponent from '../strapi/StrapiImage.vue';

defineProps({
  backgroundImage: {
    type: Object as PropType<StrapiImage>,
    required: true,
  },
  selected: {
    type: Boolean,
  },
  showDiscount: {
    type: Boolean,
    default: false,
  },
});

const slots = useSlots();

const hasButtonSlot = ref<boolean>(!!slots.button);
const hasHighlightSlot = ref<boolean>(!!slots.highlight);
</script>

<style lang="scss" scoped>
@import '../../node_modules/gportal-theme/scss/colors';

.order-card {
  color: #ffffff;
  background: $gp-midnight-1;

  &__highlight {
    position: absolute;
    width: 100%;
    color: #000;
    background: $gradient-rainbow;
  }

  &__inner {
    border: 2px solid transparent;
    transition: border 200ms ease-in-out;

    &--selected {
      border-color: $primary;
    }
  }
  &__discount {
    line-height: 2;
    font-size: 1.1rem;
    font-weight: bolder;
    border-radius: 10px 0px 0px 10px;
    top: 9% !important;
    background: #a92738cf;
    color: white;
  }
}
</style>
