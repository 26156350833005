<template>
  <common-link-handler
    :link="link"
    :data-cy="dataCyName([game.name, 'wrapper'])"
  >
    <div class="game-card rounded shadow">
      <div class="game-card__inner">
        <div class="game-card__background">
          <router-link :to="link">
            <strapi-image-overlay
              v-if="game.coverImage"
              :data-cy="dataCyName([game.name, 'cover-img'])"
              :image="game.coverImage"
              :alt-fallback="game.name"
              :ignore-overlay="isXbox"
            />
          </router-link>
        </div>
        <div
          class="game-card__platform position-absolute top-0 start-0 rounded m-3 px-2 py-1"
        >
          <strong>
            <common-platform-icon :platform="game.platform" />
          </strong>
        </div>

        <div
          v-if="isDiscountedGame"
          class="game-card__discount position-absolute end-0 px-2 py-1"
        >
          {{ DISCOUNTED_PERCENTAGE_TEXT }}
        </div>

        <div class="game-card__content">
          <div class="p-2 h-100 d-flex flex-column justify-content-end">
            <span data-cy="game-name" class="font-weight-bold">
              {{ game.name }}
            </span>
            <span
              v-if="game.startConfiguration.prices.length > 0"
              data-cy="game-price"
              v-html="startingPriceString"
            ></span>
            <router-link
              :to="link"
              data-cy="order-btn"
              class="mt-7 btn btn-sm mx-4 btn-primary button"
            >
              {{
                isPreOrder
                  ? $t('frontend/preorder-now')
                  : $t('frontend/order-now')
              }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </common-link-handler>
</template>

<script lang="ts">
import { type StrapiProduct, StrapiProductType } from '~/apollo/types/types';
import dataCyName from '~/helpers/cypress';
import {
  DISCOUNTED_PRODUCT_KEYS,
  DISCOUNTED_PERCENTAGE_TEXT,
} from '~/components/strapi/constants';

export default defineComponent({
  name: 'GameCard',
  props: {
    game: {
      type: Object as PropType<StrapiProduct>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const link = computed<string>(
      () => `/${props.game.locale}/${props.game.slug}`,
    );

    const isXbox = computed<boolean>(
      () => props.game.slug.indexOf('xbox') !== -1,
    );

    const isPreOrder = computed<boolean>(() =>
      timeIsAfterNow(props.game.releaseAt),
    );
    const { formatStrapiPrice, DEFAULT_DISCOUNT } = useNumberMoneyFormatting();

    const startingPrice = computed<string>(() =>
      formatStrapiPrice(props.game.startConfiguration.prices[0]),
    );

    const discountedStartingPrice = computed<string>(() =>
      formatStrapiPrice(
        props.game.startConfiguration.prices[0],
        DEFAULT_DISCOUNT,
      ),
    );

    // Ugly workaround since we can't access the productKey here
    const isDiscountedGame = computed<boolean>(() =>
      DISCOUNTED_PRODUCT_KEYS.includes(props.game.productKey),
    );
    const startingPriceString = computed<string>(() => {
      let priceString = t('landingpage/price_starting', {
        days: props.game.startConfiguration.duration,
        price: startingPrice.value,
      }).toString();
      if (isDiscountedGame.value) {
        // for string "3 days starting at $1.63"
        // we replace the "$1.63" part by custom html
        // should work for all translations
        const customHtml = `<s>${startingPrice.value}</s> <b>${discountedStartingPrice.value}</b>`;
        priceString = priceString.replace(startingPrice.value, customHtml);
      }
      return priceString;
    });

    return {
      isPreOrder,
      link,
      isXbox,
      dataCyName,
      isDiscountedGame,
      startingPriceString,
      DISCOUNTED_PERCENTAGE_TEXT,
    };
  },
});
</script>

<style lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';
@import '../../node_modules/gportal-theme/scss/colors';

.game-card {
  position: relative;
  width: 100%;
  padding-top: 112%; /* 25:28 Aspect Ratio, the original image is 275px x 308px */
  transition: all 0.3s ease-in;

  .button {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    &:hover {
      transform: scale(1.03);
      background-image: linear-gradient(-90deg, #ffc75e 0%, #f03da2 100%);
      box-sizing: border-box;

      .button {
        display: block;
      }

      .game-card__content {
        height: 100%;
        transition: all 0.3s ease;
        white-space: normal;

        span {
          white-space: normal;
          text-overflow: unset;
        }
      }
    }
  }

  &__inner {
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    position: absolute;
    overflow: hidden;
  }

  &__background {
    width: 100%;
    position: absolute;
    top: 0;
    background: $gp-midnight-1;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__platform {
    background: rgba(47, 58, 68, 0.6);
  }

  &__content {
    position: absolute;
    width: 100%;
    height: 70px;
    bottom: 0;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    background-image: linear-gradient(
      97deg,
      rgba(#364757, 0.6) 15%,
      rgba(#3b3b51, 0.6) 73%,
      rgba(#40304b, 0.6) 102%
    );

    .font-weight-bold {
      font-weight: bold;
    }
  }
  &__discount {
    background: rgba(47, 58, 68, 0.6);
    line-height: 2;
    font-size: 1.1rem;
    font-weight: bolder;
    border-radius: 10px 0px 0px 10px;
    top: 45% !important;
    background-color: #a92738cf;
  }
}
</style>
