<template>
  <landing-page-order-card
    :selected="selected"
    :show-discount="isDiscountedGame"
    class="order-configuration"
    :background-image="orderConfiguration.backgroundImage"
  >
    <template v-if="orderConfiguration.isFeatured" #highlight>
      <div class="text-center m-1 text-uppercase">
        <strong>{{ $t('landingpage/index/top_seller') }}</strong>
        <fa-icon
          class="position-absolute top-50 end-0 translate-middle-y me-4"
          :icon="faStar"
        />
      </div>
    </template>

    <template #button>
      <a
        v-if="selectedLocationOrderUrl"
        :href="selectedLocationOrderUrl"
        class="btn btn-primary btn-sm"
        :class="selectedLocationData.available ? '' : 'disabled'"
        data-track="landing-page-order-now"
      >
        {{ preOrder ? $t('frontend/preorder-now') : $t('frontend/order-now') }}
      </a>
    </template>

    <template #default>
      <p class="text-primary mt-3 mb-0 fs-3 fw-bold ls-1">
        {{ smallTitle }}
      </p>

      <p class="fs-1 lh-1 fw-bold mb-2">{{ mainTitle }}</p>

      <landing-page-order-configuration-extension
        v-for="extension in orderConfiguration.extensions"
        :key="extension.extensionKey"
        :extension="extension"
      />

      <p v-if="selectedLocationData" class="mb-2 fs-3">
        <template v-if="isDiscountedGame">
          <template v-if="orderConfiguration.unitType === 'Slot'">
            <s>{{ price }}</s> <b>{{ discountedStartingPrice }}*</b>
          </template>
          <i18n-t v-else keypath="landingpage/orderwidget/price" tag="span">
            <template #price>
              <s>{{ price }}</s> <b>{{ discountedStartingPrice }}*</b>
            </template>

            <template #duration>
              {{ props.orderConfiguration.duration }}
            </template>
          </i18n-t>
        </template>
        <span v-else>
          {{
            $i18n.t('landingpage/orderwidget/price', {
              duration: props.orderConfiguration.duration,
              price,
            })
          }}
        </span>
      </p>
      <landing-page-location-dropdown
        :locations="orderConfiguration.locations"
        :selected-location="selectedLocationData"
        @location-selected="setSelectedLocation"
      />
    </template>
  </landing-page-order-card>
</template>

<script setup lang="ts">
import {
  type StrapiLocalizedProductConfiguration,
  type StrapiOrderConfigurationContent,
  type StrapiPrice,
  StrapiProductType,
} from '~/apollo/types/types';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { useLocationStore } from '~/store/location';
import { DISCOUNTED_PRODUCT_KEYS } from '~/components/strapi/constants';

const props = defineProps({
  orderConfiguration: {
    required: true,
    type: Object as PropType<StrapiOrderConfigurationContent>,
  },
  productKey: {
    required: true,
    type: String,
  },
  productType: {
    required: true,
    type: String,
  },
  preOrder: {
    type: Boolean,
    default: false,
  },
  selected: {
    default: false,
    type: Boolean,
  },
});

const { $i18n } = useNuxtApp();

const locationStore = useLocationStore();

const selectedLocationSlug = computed<string>({
  get: () => {
    // If location is set in store, and selected location is available on product
    if (
      locationStore.selectedLocation != null &&
      props.orderConfiguration.locations.find(
        (l: StrapiLocalizedProductConfiguration) =>
          l.slug === locationStore.selectedLocation,
      )
    ) {
      return locationStore.selectedLocation;
    }

    // Fallback to default location for locale or first location in list
    return (
      $i18n.localeProperties.defaultServerLocation ||
      props.orderConfiguration.locations[0].slug
    );
  },
  set: (slug: string) => {
    locationStore.setLocation(slug);
  },
});

const setSelectedLocation = (location: StrapiLocalizedProductConfiguration) => {
  if (location.available) {
    selectedLocationSlug.value = location.slug;
  }
};

const selectedLocationData = computed<StrapiLocalizedProductConfiguration>(() =>
  props.orderConfiguration.locations.find(
    (l: StrapiLocalizedProductConfiguration) =>
      l.slug === selectedLocationSlug.value,
  ),
);

const selectedLocationOrderUrl = computed<string>(() => {
  if (selectedLocationData.value == null) return null;

  return `${selectedLocationData.value.orderUrl}%26skipStepOne%3D1`;
});

const smallTitle = computed<string>(() => {
  if (props.orderConfiguration.unitType === 'GB') {
    return `∞ ${$i18n.t('general/non_special_texts/slot_plural')}`;
  }

  return `${props.orderConfiguration.duration} ${$i18n.t(
    'general/non_special_texts/days',
  )}`;
});

const mainTitle = computed<string>(() => {
  if (props.orderConfiguration.unitType === 'GB') {
    return `${props.orderConfiguration.units} GB Ram`;
  }

  return `${props.orderConfiguration.units} ${$i18n.t(
    'general/non_special_texts/slot_plural',
  )}`;
});

const firstSelectedLocationPrices = computed<StrapiPrice>(() =>
  selectedLocationData.value.prices != null &&
  selectedLocationData.value.prices.length > 0
    ? selectedLocationData.value.prices[0]
    : null,
);

const { DEFAULT_DISCOUNT, formatStrapiPrice } = useNumberMoneyFormatting();

const price = computed<string>(() => {
  if (firstSelectedLocationPrices.value == null) return '';

  return formatStrapiPrice(firstSelectedLocationPrices.value);
});

const discountedStartingPrice = computed<string>(() => {
  if (firstSelectedLocationPrices.value == null) return '';

  return formatStrapiPrice(firstSelectedLocationPrices.value, DEFAULT_DISCOUNT);
});

const isDiscountedGame = computed<boolean>(() =>
  DISCOUNTED_PRODUCT_KEYS.includes(props.productKey),
);
</script>
