import { Money } from 'gportal-grpc/gportal/type/v1/money_pb';
import type { StrapiPrice } from '~/apollo/types/types';
import { DISCOUNT_APPLIED } from '~/components/strapi/constants';

export type MoneyFormatParam = Omit<Money, 'display'>;

// DEFAULT_DISCOUNT is the default discount to apply to the price
const DEFAULT_DISCOUNT = DISCOUNT_APPLIED; // final value moved to Strapi constants

export default () => {
  const { locale } = useI18n();

  /**
   * Formats the amount with the currency depending on the current locale
   *
   * @param wallet - money object with amount, fraction and currencyCode
   * @returns the formatted string
   */
  const format = (wallet: MoneyFormatParam) =>
    Intl.NumberFormat(locale.value, {
      minimumFractionDigits: wallet.fraction,
      style: 'currency',
      currency: wallet.currencyCode,
    }).format(Number(wallet.amount) / 10 ** wallet.fraction);

  const formatStrapiPrice = (price: StrapiPrice, discount = 0.0) => {
    const percentage = discount === 0 ? 1 : 1 - discount;
    const fraction = price.currency === 'JPY' ? 0 : 2;
    const amount = price.value * percentage;

    return Intl.NumberFormat(locale.value, {
      minimumFractionDigits: fraction,
      style: 'currency',
      currency: price.currency,
    }).format(Number(amount) / 10 ** fraction);
  };

  return {
    DEFAULT_DISCOUNT,
    format,
    formatStrapiPrice,
  };
};
